import styled from 'styled-components';

export const ModalContainer = styled.div`
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80vh;
  overflow: auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 1001;
`;

export const PdfControls = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 300px;
`;

export const PdfButton = styled.button`
  background-color: #007AB1;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: #005C87;
  }
`;

export const PageInfo = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color:#005C87;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: none;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  z-index: 1002;
`;



export const ErrorMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; 
  text-align: center;
  padding: 20px;
  color: red;
  font-weight: bold;
  font-size: 16px;
  background-color: #ffe6e6; 
  border: 1px solid #ffcccc;
  border-radius: 8px;
  margin: 20px 0;
`;
