import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import PropTypes from "prop-types";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { ModalContainer,ModalContent,PdfControls,PdfButton,PageInfo,CloseButton, ErrorMessage } from "./style";
import { closeIcon } from "../../../utils/icons";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;


const PdfPreview = ({ pdfFile, isOpen, onClose }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [error, setError] = useState(null);

  useEffect(() => {
    window.console.log("PDF Preview Loaded");
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setError(null);
  };

  const onDocumentLoadError = (error) => {
    setError("Failed to load the PDF document. Please try again.");
    window.console.error("Error loading PDF document:", error);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  if (!isOpen) return null;

  return (
    <ModalContainer>
      <ModalContent>
        {error ? (
          <ErrorMessage>{error}</ErrorMessage>
        ) : (
          <div>
            <Document
              file={pdfFile}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={onDocumentLoadError}
            >
              <Page pageNumber={pageNumber} />
            </Document>

            {!error && numPages > 1 && (
              <PdfControls>
                <PdfButton
                  disabled={pageNumber <= 1}
                  onClick={() => changePage(-1)}
                >
                  Previous
                </PdfButton>
                <PageInfo>
                  Page {pageNumber} of {numPages}
                </PageInfo>
                <PdfButton
                  disabled={pageNumber >= numPages}
                  onClick={() => changePage(1)}
                >
                  Next
                </PdfButton>
              </PdfControls>
            )}
          </div>
        )}
        <CloseButton onClick={onClose}>{closeIcon()}</CloseButton>
      </ModalContent>
    </ModalContainer>
  );
};

PdfPreview.propTypes = {
  pdfFile: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired, 
  onClose: PropTypes.func.isRequired, 
};

export default PdfPreview;
